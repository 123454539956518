<template>
  <div class="demo">
    <div
      class="demo_left"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div id="playVideo"></div>
    </div>
    <div class="demo_right">
      <div class="righttop">
        <el-input
          class="ipt"
          type="textarea"
          v-model="text"
          placeholder="输入文字驱动数字人"
        ></el-input>

        <el-button style="margin-left: 30px" type="primary" @click="huida"
          >说话</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// import * as ws from "../utils/ws";
import { startPlay, validAccessToken, Livetakeover } from "@/api";
import Agora_sdk from "@/utils/agora";
export default {
  data() {
    return {
      sessionId: "",
      text: "",
      loading: false,
      loadingText: "数字人加载中",
      obj: {
        version: "0.0.1",
        recycle: 1, //0重复1不重复
        sceneList: [
          {
            digital_role: {
              id: 1,
              face_feature_id: "0325_nina_s3_beauty",
              name: "小李",
              url: "https://dwg-aigc-paas-test.oss-cn-hangzhou.aliyuncs.com/materials/77/0325_nina_s3_beauty.zip",
              position: { x: 10, y: 60 },
              scale: 1,
            },
            tts_config: {
              id: "nina",
              name: "nina",
              vendor_id: 3,
              language: "zh-CN",
              pitch_offset: 0,
              speed_ratio: 1,
              volume: 400,
            },
            tts_query: { content: "《随幻空间·品牌虚拟演播厅》正式上线！天使体验官招募已经开启！你是否也想利用直播让品牌活动花样翻新，却苦于高额成本的场地限制和设备技术的复杂？你是否也想让你的培训课程遍布大江南北，却不满足于传统内容的乏善可陈和传统直播的千篇一律？随幻空间·品牌虚拟演播厅能够充分满足您的需求。这是一款由随幻智能科技自主研发并全新推出的轻量化3D实时虚拟演播软件。只需打开一个网页，就能够帮助用户以最简单的设备、最便捷的方式，自助式开启一场高品质的线上虚拟活动。海量、细致的场景分类，匹配各类应用场景，让直播一步到位。自助式装扮库、场景自由编辑，个性化空间也能轻松搭建。异地同台、云端渲染，祝您随时随地，即刻出发。大屏精讲实时录制，多种推流方式一键直播，祝您线上活动，品质升级。从新鲜精彩的品牌传播活动。到生动沉浸的精品课程录制。从高效专业的企业云端培训。到充满吸引力的品牌带货直播。扫描下方二维码，加入“MRSATGE随幻天使用户体验群”，这个夏天你也可以拥有专属的虚拟演播舞台，快快加入吧。", ssml: false },
            audios: [
              {
                url: "https://virtual-human-online.oss-cn-hangzhou.aliyuncs.com/mayahui/%E5%95%86%E6%B1%A4%E9%9F%B3%E9%A2%91%E9%A9%B1%E5%8A%A8%E6%9C%80%E6%96%B0.MP3",
              },
            ],
            backgrounds: [
              {
                type: 0,
                name: "背景",
                url: "https://dwg-aigc-paas.oss-cn-hangzhou.aliyuncs.com/test/background.png",
                rect: [0, 0, 1080, 1920],
                cycle: true,
                start: 0,
                duration: -1,
              },
            ],
            foregrounds: [
              {
                type: 0,
                name: "前景1",
                url: "https://dwg-aigc-paas.oss-cn-hangzhou.aliyuncs.com/test/frontgroud.png",
                rect: [0, 0, 310, 88],
                start: 0,
                duration: 100,
              },
            ],
            effects: {
              version: "1.0",
              beautify: {
                whitenStrength: 1,
                whiten_mode: 0,
                reddenStrength: 0.36,
                smoothStrength: 1,
                smooth_mode: 0,
                shrinkRatio: 1,
                enlargeRatio: 1,
                smallRatio: 0.1,
                narrowFace: 1,
                roundEyesRatio: 0,
                thinFaceShapeRatio: 0,
                chinLength: 0,
                hairlineHeightRatio: 0,
                appleMusle: 0,
                narrowNoseRatio: 0,
                noseLengthRatio: 0,
                profileRhinoplasty: 0,
                mouthSize: 0,
                philtrumLengthRatio: 0,
                eyeDistanceRatio: 0,
                eyeAngleRatio: 0,
                openCanthus: 0,
                brightEyeStrength: 0,
                removeDarkCircleStrength: 0,
                removeNasolabialFoldsStrength: 0,
                whiteTeeth: 0,
                shrinkCheekbone: 0,
                thinnerHead: 0,
                openExternalCanthus: 0,
                shrinkJawbone: 0,
                shrinkRoundFace: 0,
                shrinkLongFace: 0,
                shrinkGoddessFace: 0,
                shrinkNaturalFace: 0,
                shrinkWholeHead: 0,
                contrastStrength: 0.05,
                saturationStrength: 0.1,
                sharpen: 0,
                clear: 0,
                bokehStrength: 0,
              },
            },
          },
        ],
      },
    };
  },
  mounted() {
    // "{\"tts_query\":{\"content\":\"123321\",\"ssml\":false}}"
    this.initST();
    Agora_sdk.init();
  },
  methods: {
    huida() {
      Livetakeover({
        sessionId: this.sessionId,
        content: JSON.stringify({
          tts_query: {
            content: this.text,
            ssml: false,
          },
        }),
      }).then((res) => {
        console.log(res);
      });
    },
    initST() {
      this.loading = true;
      validAccessToken()
        .then((res) => {
          console.log(res);
          localStorage.setItem("token", res.token);
          this.demoFn(res.token);
        })
        .catch(() => {
          this.loadingText = "鉴权接口失败请刷新重试";
          console.log(123213);
        });
    },
    start() {
      startPlay(this.sessionId)
        .then(async (res) => {
          console.log(res);
          // setTimeout(() => {
          //   Agora_sdk.joinRoom();
          // }, 3000);
          Agora_sdk.joinRoom({ channel: null, uid: null });
        })
        .catch(() => {
          this.loadingText = "开启直播失败请刷新重试";
        });
      Agora_sdk.userPublished = (val) => {
        if (val.mediaType === "audio") {
          val.user.audioTrack.play();
        } else {
          const videoTrack = val.user.videoTrack;
          videoTrack.play("playVideo", {
            fit: "contain",
          });
        }
        this.loading = false;
      };
    },
    demoFn(token) {
      let ws = new WebSocket(
        "wss://aigc.softsugar.com/api/2dvh/v1/material/live/channel/create?Authorization=Bearer " +
          token
      );
      ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        if (msg.sessionId) {
          this.sessionId = msg.sessionId;
          ws.send(
            JSON.stringify({
              type: 1,
              sessionId: this.sessionId,
              param: JSON.stringify(this.obj),
            })
          );
        }
        if (msg.appId) {
          Agora_sdk.options.appid = msg.appId;
          Agora_sdk.options.channel = msg.channelId;
          Agora_sdk.options.role = "host";
          this.start();
        }
        if (msg.error) {
          // this.$message(msg.error);
          this.loadingText = msg.error;
        }
      };
      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            type: "0",
            // sessionId: "sessionId",
            param: "ping",
          })
        );
      };
      ws.onerror = (err) => {
        console.log(err, "onerror");
      };
      ws.onclose = (err) => {
        console.log(err, "onclose");
      };
      setInterval(() => {
        ws.send(
          JSON.stringify({
            type: "0",
            param: "ping",
          })
        );
      }, 10000);
      // setTimeout(() => {

      // }, 5000);
    },
  },
};
</script>

<style lang="less" scoped>
.demo {
  width: 100vw;
  height: 100vh;
  display: flex;
  .demo_left {
    width: 500px;
    height: 500px;
    #playVideo {
      width: 500px;
      height: 500px;
    }
  }
  .demo_right {
    flex: 1;
    overflow: auto;
    .righttop {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      .ipt {
        height: 100%;
      }
      .el-button {
        height: 100%;
      }
    }
  }
}
</style>